.control-dots {
  visibility: hidden !important;
  display: none !important;
}
.carousel-status {
  visibility: hidden !important;
  display: none !important;
}
.list-paragraph {
  margin-left: 20px;
  display: list-item;
  list-style-type: disc;
  list-style-position: inside;
}
.status-message {
  color: #fff;
  text-align: center;
  padding-top: 10px;
}
.tooltip {
  height: auto !important;
  width: 20% !important;
}

.toast-success-container {
  color: #FFF !important;
  border-radius: 8px !important;
  background: #34A853 !important;
  border: 1px solid #34A853 !important;
  box-shadow: 0px 1px 5px rgba(248, 175, 175, 0.1) !important;
  text-align: center;
}

.toast-success-container-after {
  overflow: hidden;
  position: relative;
}

.toast-success-container-after::after{
  top: 0;
  left: 0;
  content: '';
  width: 7px;
  height: 100%;
  position: absolute;
  display: inline-block;
  background-color: #34A853;
}

.toast-error-container {
  color: #FFF !important;
  border-radius: 8px !important;
  background: #EE0022 !important;
  border: 1px solid #EE0022 !important;
  box-shadow: 0px 1px 5px rgba(248, 175, 175, 0.1) !important;
  text-align: center;
}

.toast-error-container-after {
  overflow: hidden;
  position: relative;
}

.toast-error-container-after::after{
  top: 0;
  left: 0;
  content: '';
  width: 7px;
  height: 100%;
  position: absolute;
  display: inline-block;
  background-color: #EE0022;
}

.toast-info-container {
  color: #FFF !important;
  border-radius: 8px !important;
  background: #07F !important;
  border: 1px solid #07F !important;
  box-shadow: 0px 1px 5px rgba(248, 175, 175, 0.1) !important;
  text-align: center;
}

.toast-info-container-after {
  overflow: hidden;
  position: relative;
}

.toast-info-container-after::after{
  top: 0;
  left: 0;
  content: '';
  width: 7px;
  height: 100%;
  position: absolute;
  display: inline-block;
  background-color: #07F;
}
