@mixin responsive-image{
	display: block;
	height: auto;
	width: 100%;
}

@mixin font($size, $lineHeight){
	font-size: $size;
	line-height: $lineHeight;
}

@mixin display($display, $vAlign){
	display: $display;
	vertical-align: $vAlign;
}

@mixin anim($delay){
	-webkit-transition: all $delay;
	-moz-transition: all $delay;
	transition: all $delay;
}

@mixin crossBrowser($property, $css) {
	-webkit-#{$property} : $css;
	-moz-#{$property} : $css;
	-o-#{$property} : $css;
	#{$property} : $css;
}

@mixin clearfix{
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}