#footer{
  .footer-top{
    background-color: $footerTopColor;
    padding: 50px 0;
  }
  .footer-logo{
    float: left;
    width: 20%;
    img{
      @include responsive-image();
    }
  }
  .footer-column{
    float: left;
    width: 22%;
    padding: 0 60px;
    &.address-column{
      width: 36%;
    }
    h6{
      color: rgba(246, 247, 251, 0.8);
      @include font($fs4, $lh4);
      font-weight: bold;
      margin: 0 0 25px;
    }
    p{
      color: #bbb;
      @include font($fs3, $lh3);
      font-weight: 500;
      margin: 0 0 20px;
    }
    .footer-nav{
      li{
        margin: 0 0 10px;
      }
      a{
        color: #bbb;
        @include font($fs3, $lh3);
        font-weight: 500;
        @include anim(0.5s);
        &:hover{
          color: $themeColor;
        }
        &.active{
          color: $themeColor;
        }
      }
    }
    dl{
      overflow: hidden;
      margin: 0 0 5px;
      dt{
        float: left;
        svg{
          color: $themeColor;
          @include font(40px, 40px);
        }
      }
      dd{
        overflow: hidden;
        padding: 0 5px;
      }
      a{
        display: block;
        color: #bbb;
        @include font($fs3, $lh3);
        font-weight: 500;
        @include anim(0.5s);
        &:hover{
          color: $themeColor;
        }
        &.active{
          color: $themeColor;
        }
      }
    }
    .social-nav{
      margin: 15px 0 0;
      li{
        float: left;
        margin-right: 20px;
      }
      a{
        display: block;
        color: $lightColor;
        background-color: $socialColor;
        box-shadow: 0 5px 15px rgba(255,255,255,0.1);
        border-radius: 3px;
        text-align: center;
        padding: 10px;
        @include anim(0.5s);
        &:hover{
          background-color: $themeColor;
          @include crossBrowser(transform, scale(1.1))
        }
        &.active{
          background-color: $themeColor;
          @include crossBrowser(transform, scale(1.1))
        }
        svg{
          @include display(inline-block, middle);
          height: 24px;
          width: 24px;
        }
      }
    }
  }
  .footer-bottom{
    text-align: center;
    background-color: $footerBottomColor;
    padding: 30px 0;
    p{
      color: rgba(246, 247, 251, 0.5);
      @include font($fs2, $lh2);
      font-weight: 100;
      letter-spacing: 1px;
    }
    a{
      color: rgba(246, 247, 251, 0.5);
      &:hover{
        color: $themeColor;
      }
    }
  }
}





