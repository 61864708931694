#header {
  background: $lightColor;
  box-shadow: 0 0 10px rgba(0,0,0,0.2);
  .header-top {
    background-color: $headerTopColor;
    text-align: right;
    padding: 8px 0;
    dl{
      @include display(inline-block, middle);
      color: $lightColor;
      @include font($fs1, $lh1);
      margin-left: 60px;
    }
    dt{
      @include display(inline-block, middle);
      margin-right: 30px;
    }
    dd{
      @include display(inline-block, middle);
      a{
        display: block;
        color: $lightColor;
        @include anim(0.5s);
        &:hover{
          color: $themeColor;
        }
      }
      strong{
        @include display(inline-block, middle);
        border: 3px solid $themeColor;
        border-radius: 50%;
        background-color: $lightColor;
        height: 20px;
        width: 20px;
        padding: 2px;
        margin-right: 5px;
        svg{
          display: block;
          color: $themeColor;
          height: 100%;
          width: 100%;
        }
      }
      span{
        @include display(inline-block, middle);
      }
    }
  }
  .header-bottom {
    padding: 8px 0;
    .menu-button{
      display: none;
      color: $lightColor;
      background-color: $themeColor;
      box-shadow: 0 0 10px rgba(0,0,0,0.2);
      border: 1px solid $themeColor;
      border-radius: 3px;
      height: 30px;
      width: 40px;
      position: absolute;
      top: 50%;
      right: 20px;
      @include crossBrowser(transform, translateY(-50%));
      svg{
        @include font($fs3, $lh3);
        &.close-svg{
          display: none;
        }
      }
    }
  }
  .logo{
    float: left;
    width: 130px;
    @include anim(0.5s);
    &:hover{
      opacity: 0.7;
    }
    a{
      display: block;
    }
    img{
      @include responsive-image;
    }
  }
  #main-nav{
    float: right;
    margin: 28px 0 0;
    ul{
      li{
        float: left;
      }
      a{
        display: block;
        color: $headerNavColor;
        @include font($fs3, $lh3);
        border-radius: 5px;
        padding: 17px 20px;
        @include anim(0.5s);
        &:hover{
          color: $lightColor;
          background-color: $themeColor;
          box-shadow: 0 0 10px rgba(0,0,0,0.2);
        }
        &.active{
          color: $lightColor;
          background-color: $themeColor;
          box-shadow: 0 0 10px rgba(0,0,0,0.2);
        }
      }
    }
  }
}